<template>
  <div class="navigationBar">
    <div class="logo">
      <div>程序设计考试</div>
      <div />
      <div />
    </div>
    <Countdown />
    <div class="userName">
      <span style="margin-right: 10px">{{ examineeName }}</span>
      <span style="margin-right: 10px">考试{{ hasStartedExam ? '已开始' : '未开始' }}</span>
    </div>
  </div>
</template>

<script>

import { getExamineeInfo } from '@/api'
import Countdown from 'components/Countdown'

export default {
  name: 'NavigationBar',
  components: { Countdown },
  data() {
    return {
      hasStartedExam: 0,
      examineeName: ''
    }
  },

  watch: {
    '$route.path': function() {
      this.fetchStatus()
    }
  },

  mounted() {
    this.fetchStatus()
  },

  methods: {
    fetchStatus() {
      getExamineeInfo().then(({ data }) => {
        this.examineeName = data.examineeName
        this.hasStartedExam = data.hasStartedExam
        console.log(this.hasStartedExam)
      }).catch(err => {
        console.log(err)
        this.$message.warning(err.message)
      })
    },
    handleLogout() {
      this.$store.dispatch('logout')
        .then(() => {
          this.$message.success('退出登录成功')
          this.$router.replace({
            path: '/login'
          })
        })
    }
  }
}
</script>

<style scoped>

.navigationBar {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 99999;
  background: white;
}

.logo, .userName {
  width: fit-content;
  white-space: nowrap;
  min-width: 15vw;
}

.userName {
  display: flex;
  justify-content: center;
  align-items: center;

}

.navigationBar {
  height: 60px;
  padding: 0 20px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo > div:nth-of-type(1) {
  font-size: 38px;
  color: #006CF2;
  font-weight: 900;
  letter-spacing: 5px;
  line-height: 40px;
}

.logo > div:nth-of-type(2), .logo > div:nth-of-type(3) {
  width: 120px;
  height: 2px;
  background: linear-gradient(to bottom, #0F5CF2, #96ACF7);
}

.logo > div:nth-of-type(3) {
  margin-top: 5px;
}

.name {
  margin-right: 10px;
}

.quit {
  height: 30px;
  line-height: 0;
  border: none;
  background: #3979F3;
  color: white;
}

.quit:hover {
  background: rgba(57, 121, 243, 0.8);
  color: white;
}
</style>
