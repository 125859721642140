import Vue from 'vue'
import Vuex from 'vuex'
import request from '../utils/request'
import { COOKIE_KEYS, getCookie, removeCookie, setCookie } from '@/utils/cookies'
import { encrypt } from '@/utils/rsa-encrypt'
import { startExam } from '@/api'

Vue.use(Vuex)

const defaultState = {
  token: getCookie(COOKIE_KEYS.token)
}

const store = new Vuex.Store({
  state: defaultState,

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
      setCookie(COOKIE_KEYS.token, token)
    },
    RESET_TOKEN: state => {
      state.token = undefined
      removeCookie(COOKIE_KEYS.token)
    }
  },

  actions: {
    login({ commit, dispatch }, { examRegistrationNumber, loginCode }) {
      return new Promise((resolve, reject) => {
        // encrypt(password)
        //   .then(encryptedPassword => request.post('/examinee/login', { number, password: encryptedPassword }))
        //   .then(r => {
        //     console.log(r)
        //     commit('SET_TOKEN', r.data)
        //     resolve(r)
        //   })
        //   .catch(e => {
        //     reject(e)
        //   })
        request.post('/examinee/login', { loginCode })
          .then(res => {
            commit('SET_TOKEN', res.data)
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    logout({ commit }) {
      commit('RESET_TOKEN')
    },

    startExam() {
      return new Promise((resolve, reject) => {
        startExam()
          .then(resolve)
          .catch((err) => {
            if (err.response && err.response.data) {
              reject(err.response.data)
            } else {
              reject(err)
            }
          })
      })
    }
  },

  getters: {
    examInfo: state => state.examInfo,
    examineeInfo: state => state.examineeInfo,
    token: state => state.token,
    examStarted: state => state.examStarted
  },

  modules: {
  }
})

export { store }
