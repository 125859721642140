import Cookies from 'js-cookie'

export const COOKIE_KEYS = {
  token: 'SIDE_STUDENT_TOKEN',
  xxMinutesNotified: (minute) => `${minute}_MINUTES_NOTIFIED`
}

export const getCookie = (key) => Cookies.get(key)

export const setCookie = (key, value) => Cookies.set(key, value)

export const removeCookie = (key) => Cookies.remove(key)

export const removeAllCookies = () => {
  Object.keys(COOKIE_KEYS).forEach(key => {
    removeCookie(key)
  })
}
