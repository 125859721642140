import Vue from 'vue'
import VueRouter from 'vue-router'
import { store } from '@/store'
import { getExamineeInfo, getExamInfo } from '@/api'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index'),
    meta: {
      showNav: false
    }
  },
  {
    path: '/information',
    name: 'information',
    component: () => import('../views/information/index'),
    meta: {
      showNav: true
    }
  },
  {
    path: '/judge',
    name: 'Judge',
    component: () => import('../views/judge/index'),
    meta: {
      showNav: true
    }
  },
  {
    path: '/tests',
    name: 'tests',
    component: () => import('../views/tests/index'),
    meta: {
      showNav: true
    }
  },
  {
    path: '/tests/edit/',
    name: 'edit',
    component: () => import('../views/tests/edit/index'),
    meta: {
      showNav: true
    }
  },
  {
    path: '/tests/view/:id',
    name: 'edit',
    component: () => import('../views/tests/edit/index'),
    meta: {
      showNav: true,
      readonly: true
    }
  },
  {
    path: '/end',
    name: 'end',
    component: () => import('../views/end/index'),
    meta: {
      showNav: false
    }
  }
]

// const fetched = false

const routerBeforeEach = async(to, from, next) => {
  const { token } = store.getters

  // 未登录只能访问登录页
  if (!token) {
    return to.path !== '/login' ? next({ path: '/login', replace: true }) : next()
  }

  // const { data: examineeInfo } = await getExamineeInfo()
  // const { data: exam } = await getExamInfo()

  // if (exam) {
  //   const { examEndTime } = exam
  //   if (examEndTime < Date.now()) {
  //     return to.path !== '/end' ? next({ path: '/end', replace: true }) : next()
  //   }
  // }
  //
  // if (examineeInfo) {
  //   const { hasStartedExam } = examineeInfo
  //
  //   if (hasStartedExam) {
  //     return !to.path.includes('/test') ? next({ path: '/tests', replace: true }) : next()
  //   } else {
  //     return !to.path.includes('/information') ? next({ path: '/information', replace: true }) : next()
  //   }
  // }

  next()
}

const router = new VueRouter({
  routes
})

router.beforeEach(routerBeforeEach)

export default router
