import axios from 'axios'
import { COOKIE_KEYS, getCookie } from './cookies'

const { domain } = document

const serviceBase = 'http://175.27.190.185:25569/examination/web/v1/venue'

export const baseURL = 'https://api.algorithms2019.com/examination/web/v1/venue'

const fakeUrl = 'https://api.algorithms2019.com/algorithm/foreground/v1/'

const service = axios.create({
  baseURL,
  timeout: 30000
})

export const fakeRequest = axios.create({
  baseURL: fakeUrl,
  timeout: 30000
})
service.interceptors.request.use(
  config => {
    const token = getCookie(COOKIE_KEYS.token)

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }

    return config
  },
  err => {
    return Promise.reject(err)
  }
)

service.interceptors.response.use(
  res => {
    const { data } = res

    if (data && data.code && +data.code !== 200) {
      return Promise.reject(data)
    }

    return res.data
  },
  err => {
    return Promise.reject(err)
  }
)

export default service
