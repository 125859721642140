<template>
  <div>
    <div class="exam-countdown">
      {{ countdownLabel }}：
      {{ timestampToDurationString(countdown) }}
    </div>
  </div>
</template>

<script>
import { COOKIE_KEYS, getCookie, setCookie } from '@/utils/cookies'
import { getExamineeInfo, getExamInfo } from '@/api'

export default {
  name: 'Countdown',
  data() {
    return {
      deferTime: undefined,
      intervalId: undefined,
      examInfo: {
        examStartTime: undefined,
        examEndTime: undefined
      },
      now: undefined
    }
  },
  computed: {
    countdownLabel() {
      return this.startCountdown > 0 ? '考试开始倒计时' : '考试结束倒计时'
    },
    startCountdown() {
      return this.examInfo.examStartTime - this.now ?? 0
    },
    endCountdown() {
      return (parseInt(this.examInfo.examEndTime) - parseInt(this.now)) ?? 0
    },
    countdown() {
      return this.startCountdown > 0 ? this.startCountdown : this.endCountdown
    }
  },
  watch: {
    endCountdown(countdown) {
      if (countdown <= 0) {
        // clearInterval(this.intervalId)
        this.$router.push('/end')
      }

      const timeToNotify = [
        { minute: 30, label: '半小时' },
        { minute: 15, label: '15分钟' },
        { minute: 10, label: '10分钟' },
        { minute: 5, label: '5分钟' }
      ]

      for (const time of timeToNotify) {
        const { minute, label } = time
        const cookieKey = COOKIE_KEYS.xxMinutesNotified(time.minute)

        if (this.endCountdown < minute * 60 * 1000 && +getCookie(cookieKey) !== 1) {
          setCookie(cookieKey, 1)
          this.$notify({
            message: `考试剩余时间已不足${label}`,
            title: '请注意',
            duration: 0,
            type: 'warning'
          })
        }
      }
    }
  },

  mounted() {
    getExamInfo().then(({ data }) => {
      this.examInfo = data
      this.now = +this.examInfo.serverTime

      this.intervalId = setInterval(() => {
        this.now += 1000
      }, 1000)
    }).catch(e => {
      this.$message.error(`获取考试信息失败: ${e.message ?? e.toString()}`)
    })
  },

  methods: {
    timestampToDurationString(t) {
      // millisecond to second
      t = t ? parseInt('' + t / 1000) : 0

      const h = parseInt('' + t / 3600).toString().padStart(2, '0')
      const m = parseInt('' + t % 3600 / 60).toString().padStart(2, '0')
      const s = (t % 60).toString().padStart(2, '0')

      return `${h}:${m}:${s}`
    }

  }
}
</script>

<style scoped>
.exam-countdown {
  display: flex;
  align-items: center;
  height: 1.8vw;
  padding: 5px 50px;
  border-radius: 8px;
  background: #3979F3;
  font-size: 20px;
  color: white;
  font-weight: 600;
}
</style>
