import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueCodemirror from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'

Vue.use(VueCodemirror)
Vue.use(ElementUI, { size: 'medium' })

import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
Vue.use(mavonEditor)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

import moment from 'moment'
moment.locale('zh-cn')

Vue.filter('dateFormat', function(value) {
  return moment(+value).format('YYYY-MM-DD HH:mm:ss')
})
