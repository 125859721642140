import request from '@/utils/request'

export const getExamineeInfo = () => {
  return request.get(`/examinee/info`)
}

export const getExamInfo = () => {
  return request.get(`/exam/info`)
}

export const getQuestionList = ({ classroomExamId }) => {
  return request.post('/exam/question/list', {
    classroomExamId
  })
}

export const downloadPaper = () => {
  return request.get('/exam/paper')
}

export const startExam = () => {
  // const { data } = await request.get(`/examinee/test/info`)
  // return {
  //   data: { ...data, examEndTime: 1634748630000, examStartTime: 1634748600000 }
  // }

  return request.get(`/exam/start`)
}

export const getQuestion = ({ questionId, classroomExamId }) => {
  return request.post(`/exam/question/id`, {
    questionId,
    classroomExamId
  })
}

export const submitQuestion = ({ classroomExamId, questionId, code, ojLanguage }) => {
  return request
    .post('/exam/question/submit', {
      classroomExamId,
      questionId,
      code,
      ojLanguage
    })
}
